<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                href="#"
                :disabled="item.disabled"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
            <v-card-title>Create Service User</v-card-title>
            <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
                <v-container class="fill-height"
                fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form.name.name"
                                :rules="form.name.rule"
                                label="Name"
                                required
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form.email.name"
                                :rules="form.email.rule"
                                label="Email"
                                required
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form.password.name"
                                :rules="form.password.rule"
                                label="Password"
                                :append-icon="form.password.show ? 'mdi-eye' : 'mdi-eye-off'" :type="form.password.show ? 'text' : 'password'" @click:append="form.password.show = !form.password.show"
                                required
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                                dense
                                outlined
                                v-model="form.cpassword.name"
                                :rules="form.cpassword.rule"
                                label="Confirm Password"
                                :append-icon="form.cpassword.show ? 'mdi-eye' : 'mdi-eye-off'" :type="form.cpassword.show ? 'text' : 'password'" @click:append="form.cpassword.show = !form.cpassword.show"
                                required
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <!--
                        <v-col cols="12" md="12">
                            <span class="text-body-2">Status</span>
                            <v-switch
                            color="#25283D"
                            class="col-1 pl-0"
                            v-model="form.status"
                            :label="`${form.status ? 'Enabled' : 'Disabled'}`"
                            hide-details
                            ></v-switch>
                        </v-col>
                        -->
                        <v-col cols="12" md="12">
                    <span class="text-body-2">Choose Operator Access to give access to</span>
                    <v-checkbox dense hide-details @change="selectAll_operator" class="col-5 pl-0" v-model="select_all_operator">
                        <template v-slot:label class="checkbox-role"><span style="width: 100%">All Operator</span></template>
                    </v-checkbox>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Or select specifically from below</span>
                </v-col>
                <v-col cols="12" offset-md="6" md="6">
                    <v-text-field placeholder="Search Operator" outlined class="search-textfield" hide-details dense v-model="search_operator"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        :headers="form.operator.header"
                        v-model="form.operator.selected"
                        :items="form.operator.items"
                        show-select
                        item-key="OperatorID"
                        hide-default-footer
                        :search="search_operator"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        dense
                        @toggle-select-all="removeSelectAll_operator"
                        @input="execFilter('operator')"
                        disable-pagination
                        v-scroll:#virtual-scroll-table-op="onScroll_operator"
                        id="virtual-scroll-table-op"
                    >
                    <template
                        v-if="start_operator > 0"
                        v-slot:body.prepend
                    >
                        <tr>
                        <td
                            :colspan="form.operator.header.length"
                            :style="'padding-top:'+startHeight_operator+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    <template
                        v-if="start_operator + perPage_operator < form.operator.items.length"
                        v-slot:body.append
                    >
                        <tr>
                        <td
                            :colspan="form.operator.items.length"
                            :style="'padding-top:'+endHeight_operator+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    </v-data-table>
                    <v-input
                        error
                        readonly
                        v-model="form.operator.selected"
                        :rules="form.operator.rule"
                        height="0"
                        class="hide-input-text mt-2"
                    >
                    </v-input>
                </v-col>
                        <v-col cols="12" md="12">
                        <span class="text-body-2">Choose Car Park Access to give access to</span>
                        <v-checkbox dense hide-details @change="selectAll" class="col-2 pl-0" v-model="select_all">
                           <template v-slot:label class="checkbox-role"><span style="width: 100%">All Car Park</span></template>
                        </v-checkbox>
                     </v-col>
                     <v-col cols="12" md="12">
                        <span class="text-body-2">Or select specifically from below</span>
                     </v-col>
                     <v-col cols="12" offset-md="6" md="6">
                        <v-text-field placeholder="Search Car Park" outlined class="search-textfield" hide-details dense v-model="search"></v-text-field>
                     </v-col>
                     <v-col cols="12" md="12">
                        <v-data-table
                           :headers="form.carpark.header"
                           v-model="form.carpark.selected"
                           :items="form.carpark.items"
                           show-select
                           item-key="CarParkID"
                           hide-default-footer
                           :search="search"
                           loading-text= "Loading Data... Please wait"
                           :items-per-page="100"
                           dense
                           @toggle-select-all="removeSelectAll"
                           disable-pagination
                           v-scroll:#virtual-scroll-table-cp="onScroll"
                           id="virtual-scroll-table-cp"
                           >
                           <template
                              v-if="start > 0"
                              v-slot:body.prepend
                              >
                              <tr>
                                 <td
                                    :colspan="form.carpark.header.length"
                                    :style="'padding-top:'+startHeight+'px'"
                                    >
                                 </td>
                              </tr>
                           </template>
                           <template
                              v-if="start + perPage < form.carpark.items.length"
                              v-slot:body.append
                              >
                              <tr>
                                 <td
                                    :colspan="form.carpark.items.length"
                                    :style="'padding-top:'+endHeight+'px'"
                                    >
                                 </td>
                              </tr>
                           </template>
                        </v-data-table>
                        <v-input
                           error
                           readonly
                           v-model="form.carpark.selected"
                           :rules="form.carpark.rule"
                           height="0"
                           class="hide-input-text mt-2"
                           >
                        </v-input>
                     </v-col>
                        <v-card-actions>
                            <v-btn class="mt-4" tile small color="primary" type="submit" :loading="btn_loading"><v-icon left x-small>fa fa-save</v-icon> Save</v-btn>
                            <v-btn class="mt-4" tile small color="error" type="button" @click="goBack"><v-icon left x-small>fa fa-ban</v-icon> Cancel</v-btn>
                        </v-card-actions>
                    </v-row>
                </v-container>
            </v-form>
            <v-overlay
            :absolute="absolute"
            :value="overlay"
            >
                <v-progress-circular
                indeterminate
                color="primary"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
        <v-snackbar
            v-model="swal.notification"
            top
            :color="swal.scolor"
            >
            {{ swal.message }}
        </v-snackbar>
        <alertbox :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import create from '.././modals/AddRate';
import edit from '.././modals/EditRate';
import alertbox from '../modals/Alert';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";
import * as AppStorage from '../../assets/js/storage';

export default {
    name: 'Create Service User',
    title: 'Create Service User',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },

                {
                text: 'Service User',
                disabled: true,
                href: '#',
                },

                {
                text: 'Create Service User',
                disabled: true,
                href: '#',
                }
            ],
            role: {
                header: [
                    { text: '#', value: 'row', width: '5%' },
                    { text: 'Name', value: 'name', width: '90%' },
                ],
                items: [],
                itemsFull: [],
                selected: [],
                loader: false,
                disable: false,
                rule: [],
                forapi: ''
            },
            items: {},
            search: '',
            tableloading: false,
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Day', value: 'day', width: '20%' },
                { text: 'Action', value: 'add', width: '10%' },
                { text: 'Rates', value: 'rates', width: '10%' },
            ],
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                alertmodal: false,

                modal_edit: false,
                modal_delete: false,
            },
            form: {
                name: {name: '', rule: []},
                email: {name: '', rule: []},
                status: false,
                password: {name: '', rule: [], show: false},
                cpassword: {name: '', rule: [], show: false},
                operator: {
                    header: [
                        { text: 'ID', value: 'OperatorID', width: '5%' },
                        { text: 'Name', value: 'name', width: '90%' },
                    ],
                    items: [],
                    itemsFull: [],
                    selected: [],
                    loader: false,
                    disable: false,
                    rule: [],
                    forapi: ''
                },
                carpark: {
                    header: [
                        { text: 'ID', value: 'CarParkID', width: '5%' },
                        { text: 'Name', value: 'name', width: '90%' },
                    ],
                    items: [],
                    itemsFull: [],
                    selected: [],
                    loader: false,
                    disable: false,
                    rule: [],
                    forapi: ''
                },
            },
            componentloader: false,
            role_selected: [],
            perm_list: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false,
            tab: null,
            tab_items: [
            { tab: 'Profile Details', content: 'Tab 1 Content' },
            { tab: 'Car Park Access', content: 'Tab 2 Content' },
            ],

            start: 0,
            timeout: null,
            rowHeight: 24,
            perPage: 25,

            search_operator: '',
            select_all_operator: false,

            start_operator: 0,
            timeout_operator: null,
            rowHeight_operator: 10,
            perPage_operator: 10,
        }
    },

    components: {create, edit, alertbox, 'text-editor': TextEditor},

    async created(){
        this.$vuetify.goTo(0);
        await this.getOperatorFull();
        await this.getCarParkFull();

        this.$watch(() => this.form.operator.selected, function(value) {
            if (value.length > 0){
                this.select_all_operator = false;
            }
        });
        this.$watch(() => this.form.carpark.selected, function(value) {
            if (value.length > 0){
                this.select_all = false;
            }
        });
    },

    computed: {
        itemsLimited() {
            console.log('movingggg');
        return this.form.carpark.items.slice(this.start, this.perPage+this.start);
        },
        startHeight() {
            console.log('movingggg');
        return this.start * this.rowHeight - 32;
        },
        endHeight() {
            console.log('movingggg');
        return this.rowHeight * (this.form.carpark.items.length - this.start);
        },

        itemsLimited_operator() {
        return this.form.operator.items.slice(this.start_operator, this.perPage_operator+this.start_operator);
        },
        startHeight_operator() {
        return this.start_operator * this.rowHeight_operator - 32;
        },
        endHeight_operator() {
        return this.rowHeight_operator * (this.form.operator.items.length - this.start_operator);
        },

        confirmPword(){
            if (this.form.password.name !== this.form.cpassword.name){
                return false;
            }

            else{
                return true;
            }
        },
    },

    methods: {
        async getOperatorFull(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllOperator", {search: ''});
                let dt = response.data.record;
                
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;

                        this.form.operator.items.push(dt[i]);
                        this.form.operator.itemsFull.push(dt[i]);
                        }
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllCarpark2", {});
                let dt = response.data.record;
                //this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                //this.form[2].itemsFull.push({name: "Select Car Park", CarParkID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                        dt[i].name =  `${dt[i].CarParkID} - ${dt[i].CarParkName}`;

                        this.form.carpark.items.push(dt[i]);
                        this.form.carpark.itemsFull.push(dt[i]);
                        }
                    }
                }

                list = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async createServiceUserAccount(){
            this.btn_loading = true;
            this.tableloading = true;

            try{
                //this.swal.scolor = 'green';
                let req = {customeremail: this.form.email.name, customerusername: this.form.name.name, desiredpassword: this.form.cpassword.name, operatorpermission: this.form.operator.forapi, carparkpermission: this.form.carpark.forapi};

                let response = await this.$store.dispatch("createServiceUserAccount", req);
                if (response.data.code === 'AP000'){
                    this.$router.push({name: 'Service User', query: {action: 'createUserAndProfile'}});
                        //await this.getUsers();
                        //this.swal.notification = true;
                        //this.swal.scolor = 'green';
                        //this.swal.message = `${ERR.HANDLE(response.data.code)}: Profile Created!`;
                }

                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This profile already exists`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        async addProfileExistingUser(loginEmailID){
            this.btn_loading = true;
            this.tableloading = true;

            try{
                //this.swal.scolor = 'green';
                let req = {loginemailid: loginEmailID, operatorusername: this.form.name.name, type: 2, operator: this.form.operator.forapi, carpark: this.form.carpark.forapi};

                let response = await this.$store.dispatch("createProfileCustomer", req);
                if (response.data.code === 'AP000'){
                    this.$router.push({name: 'Users', query: {action: 'updateExistingProfile'}});
                        //await this.getUsers();
                        //this.swal.notification = true;
                        //this.swal.scolor = 'green';
                        //this.swal.message = `${ERR.HANDLE(response.data.code)}: Profile Created!`;
                }

                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This profile already exists`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        goBack(){
            this.$router.push({name: 'Users'});
        },

        async execFilter(type){
            if (type == 'operator'){
                let self = this;
                if (self.form.operator.selected.length > 0){
                    //let carpark = this.form[2].itemsFull.filter(item => {
                      //  return item.OperatorID == self.form[1].selected;
                    //});

                    let selectedop = this.form.operator.selected.map(item => item.OperatorID);
                    let carpark = this.form.carpark.itemsFull.filter(itm => selectedop.includes(itm.OperatorID));

                    this.form.carpark.items = [];
                    //this.form[2].selected = [];

                    //this.form[2].carpark.push({name: "Select Car Park", CarParkID: ""});
                    for(var i=0; i<carpark.length; i++){
                        //carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        //this.carpark.items.push(carpark[i]);

                        carpark[i].name =  `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;

                        this.form.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }
                }

                if (self.form.operator.selected.length == 0){
                    this.form.carpark.items = [];
                    //this.form[2].selected = [];

                    if (this.select_all_operator){
                        let selectedop = this.form.operator.itemsFull.map(item => item.OperatorID);
                        let carpark = this.form.carpark.itemsFull.filter(itm => selectedop.includes(itm.OperatorID));

                        for(var i=0; i<carpark.length; i++){
                            carpark[i].name =  `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                            this.form.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                        }
                    }
                }
            }

            let op = this.form.operator.items.map(itm => itm.OperatorID);
            let removeselectedoperatornotshown = this.form.operator.selected.filter(itm => op.includes(itm.OperatorID));
            this.form.operator.selected = removeselectedoperatornotshown;

            let cp = this.form.carpark.items.map(itm => itm.CarParkID);
            let removeselectedcarparknotshown = this.form.carpark.selected.filter(itm => cp.includes(itm.CarParkID));
            this.form.carpark.selected = removeselectedcarparknotshown;
        },

        clearRules(){
            this.form.name.rule = [];
            this.form.email.rule = [];
            this.form.operator.rule = [];
            this.form.carpark.rule = [];
        },

        async beforeCreateForm(flag){
            this.clearRules();
            
            this.form.name.rule = [
                v => !!v || "Name is required"
            ];

            this.form.email.rule = [
                v => !!v || 'Email is required',
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter a valid email address',
            ];

            this.form.password.rule = [
                v => !!v || "Password is required"
            ];

            this.form.cpassword.rule = [
                v => !!v || "Confirm Password is required",
                v => this.confirmPword || "Please ensure your password matches"
            ];

            if (!this.select_all_operator){
                this.form.operator.rule = [
                    v => !v.length == 0 || 'Field is required',
                ];
            }

            if (!this.select_all){
                this.form.carpark.rule = [
                    v => !v.length == 0 || 'Field is required',
                ];
            }

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },

        onClosed(value){
            console.log(value);
            if (value == false){
                this.ma.alertmodal = value;
                this.ma.modal_delete = value;
            }
        },

        async onConfirm(value){
            console.log(value);
            if (value == true){
                this.ma.alertmodal = false;

                //this.addValuePass(formatteddays);

                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_delete = false;
                    this.deleteProfile();
                }

                if (this.ma.modal_action_type == 'create')
                {
                    this.ma.alertmodal = false;
                    let response = await this.$store.dispatch("checkCustomerExist", {email: this.form.email.name});
                    let addneworexisting = response.data.emailexist;
                    let emailid = response.data.selectedloginemailid;
                    
                    if (this.select_all){
                        this.form.carpark.forapi = '[4ALLCP]';
                    }

                    else{
                        let carpark = this.form.carpark.selected.map(item => item.CarParkID);
                        this.form.carpark.forapi = `[${carpark.join()}]`;
                    }

                    if (this.select_all_operator){
                        this.form.operator.forapi = '[4ALLOP]';
                    }

                    else{
                        let operator = this.form.operator.selected.map(item => item.OperatorID);
                        this.form.operator.forapi = `[${operator.join()}]`;
                    }

                    if (addneworexisting == false)
                        this.createServiceUserAccount();

                    else{
                        this.addProfileExistingUser(emailid);
                    }
                }
            }
        },

        onScroll(e) {
            // debounce if scrolling fast
            this.timeout && clearTimeout(this.timeout);
        
            this.timeout = setTimeout(() => {
                const { scrollTop } = e.target;
                const rows = Math.ceil(scrollTop / this.rowHeight);

                this.start = rows + this.perPage > this.form.carpark.items.length ?
                this.form.carpark.items.length - this.perPage: rows;

                this.$nextTick(() => {
                e.target.scrollTop = scrollTop;
                });
            }, 20);
        },

        selectAll(){
            if (this.select_all){
                this.form.carpark.selected = [];
            }
        },

        removeSelectAll(){
            this.select_all = false;
        },

        onScroll_operator(e) {
            // debounce if scrolling fast
            this.timeout && clearTimeout(this.timeout);
        
            this.timeout = setTimeout(() => {
                const { scrollTop } = e.target;
                const rows = Math.ceil(scrollTop / this.rowHeight);

                this.start_operator = rows + this.perPage_operator > this.form.operator.items.length ?
                this.form.operator.items.length - this.perPage_operator: rows;

                this.$nextTick(() => {
                e.target.scrollTop = scrollTop;
                });
            }, 20);
        },

        selectAll_operator(){
            if (this.select_all_operator){
                this.form.operator.selected = [];
                this.execFilter('operator');
            }
        },

        removeSelectAll_operator(){
            this.select_all_operator = false;
        },
    }
}
</script>